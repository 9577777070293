.dashboard {
    .topCard {
        &_per {
            background-color: #f7f7f7;
            font-size: 12px;
            padding: 0.3rem 0.5rem;
            border-radius: 4px;
            font-family: "Roboto";
            font-weight: 700;
        }
        .text {
            color: #c2c2c2;
        }
        &:hover {
            background-color: $PRIMARY_COLOR;
            .title {
                color: #fff;
            }
            .value {
                color: #fff;
            }
            .text {
                color: #fff;
            }
        }
    }
}
.hideHover{
    .ant-table-thead{
        cursor: default !important; 
    }
}