@tailwind base;
@tailwind components;
@tailwind utilities;

@import "antd/dist/reset.css";
@import "./constants.scss";
@import "./common.scss";
@import "./sidebar.scss";
@import "./tabs.scss";
@import "./table.scss";
@import "./Dashboard/index.scss";
@import "./modal.scss";
@import "./antdCustom.scss";

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/Roboto/Roboto-Medium.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-style: normal;
  font-display: swap;
}

.roboto {
  font-family: "Roboto";
}

.roboto-medium {
  font-family: "Roboto-Medium";
}

.montserrat {
  font-family: "Montserrat";
}

.montserrat-medium {
  font-family: "Montserrat-Medium";
}

.inter {
  font-family: "Inter";
}
.datePicker {
  width: 0;
  height: 0;
  padding: 0;
}
.ant-picker-suffix {
  display: none !important;
}

.ant-switch {
  background-color: lightgray;
}

.ant-switch-checked {
  background-color: #94cdaf !important;
}

.ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
}



